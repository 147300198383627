import React from 'react'

import { openDeepLink, getUserAgentInfo } from 'src/utils'

// Styles & Images
import 'src/components/open-app/scss/style.scss'
import ExodusSymbol from 'static/img/exodus-symbol.svg'

// Main component:
const OpenApp = ({ link }) => {
  // need to check window to avoid navigator null error during SSR
  if (typeof window !== 'undefined') {
    // show open app banner for Android and for iOS non-safari browsers
    const { isIOS, isSafari, isAndroid } = getUserAgentInfo()

    if (isIOS) {
      if (isSafari) return null
    } else {
      if (!isAndroid) return null
    }
  } else {
    // avoid app banner showing on split second on desktop
    return null
  }

  return (
    <div className="x__open-app-banner">
      <div className="x__info">
        <img
          className="x__info__logo"
          src={ExodusSymbol}
          alt="Exodus Logo"
          width="30"
          height="30"
        />

        <div className="x__info__text">
          <span className="x__info__text-name">Exodus</span>
          <span className="x__info__text-message">Open in the Exodus app</span>
        </div>
      </div>

      <div className="x__actions">
        <a
          className="x__actions__button"
          target="_blank"
          rel="noreferrer noopener"
          onClick={() => {
            openDeepLink(link)
          }}
        >
          <span className="x__actions__button__text">OPEN</span>
        </a>
      </div>
    </div>
  )
}

export default OpenApp
