import React from 'react'
import PropTypes from 'prop-types'
import PageHead from 'src/components/head/PageHead'
import ConfirmLayout from 'src/templates/confirm'
import { SITE_URL } from 'src/constants'
import { getUserAgentInfo } from 'src/utils'

// Styles & Images
import SeedlessBackupIcon from 'static/confirm/images/backup-vault--icon.svg'

// Main component:
const BackupVaultPage = ({ location, params }) => {
  let isMobile = false
  if (typeof window !== 'undefined') {
    const { isIOS, isAndroid } = getUserAgentInfo()
    isMobile = isIOS || isAndroid
  }

  const mobileAppLink = location.pathname.replace('/m/', '')
  const websiteLink = isMobile ? 'exodus://' + mobileAppLink : SITE_URL + location.pathname
  return (
    <ConfirmLayout
      icon={SeedlessBackupIcon}
      iconSize="largest"
      page="backup-vault"
      mobileAppLink={mobileAppLink}
      location={location}
      subHeadingProps={{
        beforeText: (
          <span>
            To continue, <a href={websiteLink}>open this link</a> on a mobile device that has Exodus
            installed
          </span>
        ),
      }}
    />
  )
}

// Main component props:
BackupVaultPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

BackupVaultPage.defaultProps = {}

export default BackupVaultPage

// <head> component:
export function Head() {
  return <PageHead page="backup-vault" noindex />
}
