import { BROWSER_EXTENSION_BUILDS } from 'src/constants'

import getUrlParam from './getUrlParam'

const openDeepLink = (path) => {
  const build = getUrlParam('build')
  const targetsBrowserExtension = BROWSER_EXTENSION_BUILDS.includes(build)
  // Browser Extension: no support for deeplinks, call injected function to communicate
  if (targetsBrowserExtension) {
    try {
      window.exodus.internal.openExtensionPopup({ route: path })
    } catch (err) {}
    return
  }

  let deepLinkUrl = `exodus://${path}`

  try {
    if (/\beden=/.test(window.location.search)) deepLinkUrl = `exodus-eden://${path}`
  } catch (err) {}

  try {
    window.location = deepLinkUrl
  } catch (err) {
    if (console && console.log) console.log(err)
  }
}

export default openDeepLink
