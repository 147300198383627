const getUrlParams = (paramNames) => {
  const searchParams = new URLSearchParams(window.location.search)

  const params = {}
  if (paramNames) {
    paramNames.forEach((paramName) => {
      params[paramName] = searchParams.get(paramName)
    })
  } else {
    for (const [paramKey, paramValue] of searchParams) {
      params[paramKey] = paramValue
    }
  }

  return params
}

export default getUrlParams
