/*
 * from: https://stackoverflow.com/questions/8495687/split-array-into-chunks
 */

const chunkArray = (array, chunkSize) => {
  const chunked = []

  for (let i = 0; i < array.length; i += chunkSize) {
    chunked.push(array.slice(i, i + chunkSize))
  }

  return chunked
}

export default chunkArray
